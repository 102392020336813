import './src/css/typography.css'
import './src/css/index.css'
import './src/css/code.css'

// eslint-disable-next-line
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

// eslint-disable-next-line
export const onClientEntry = () => {
  window.onload = () => {
    document.body.classList.remove('no-js')
  }
}
